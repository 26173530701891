@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Bloody;
    src: url(./assets/DoubleFeature-KvAe.ttf) format('truetype');
  }
}
